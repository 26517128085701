import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa6";
import RegularBadge from "../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../assets/icons/business_verification_badge.svg";
import PrideBadge from "../assets/icons/pride_verification_tag.svg";
import {useState} from "react"
import axios from "axios";
import { API_ROUTE } from "../utils/Api";
import { refreshAccessToken } from "../utils/RefreshAccessToken";


function shortenText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}




const Container = styled.div``;
const UserItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;
const UserImageAndTextCon = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const UserImageCon = styled.div`
    width: 40px;
    height: 40px;
    border-radius:100%;
    background: var(--twikvibe-grey-color);
`;
const UserImage = styled.img`
    border-radius:100%;
    width:100%;
    height:100%;
    object-fit: cover;
    background: var(--twikvibe-grey-color);
`;

const UserTextCon = styled.div`
    display:flex;
    flex-direction:column;
    gap:4px;
`;
const UserName = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 600;
    display:flex;
    gap:5px;
    align-items; center;
`;

const UserAbout = styled.div`
    color:var(--twikvibe-grey-color);
    font-size: 14px;
`;

const VerficationImageContainer = styled.div`
    position: relative;
    width: 14px;
    // height: 18px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;
const FollowButton = styled.div`
    color: var(--twikVibe-brand-color);
    padding: 10px ;
    border-radius: 50px;
    // background-color: var(--twikVibe-light-theme-bg-color);
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;

    &:hover{
        color: var(--twikvibe-grey-color);
    }
`;


const Users = ({users, setUsers}) => {
    const [buttonScales, setButtonScales] = useState([]); // Array to hold the scale for each button

    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')


    const toggleSubscribeToUser = async({e, userIamSubscribingToId, index})=>{

        e.preventDefault();
        e.stopPropagation();

        // Increase the scale only for the clicked button
        setButtonScales(prevScales => {
            const newScales = [...prevScales];
            newScales[index] = 0.9; // Set a slightly larger scale for the clicked button
            return newScales;
        });

        // Reset scale after a short delay
        setTimeout(() => {
            setButtonScales(prevScales => {
                const newScales = [...prevScales];
                newScales[index] = 1; // Reset the scale to normal
                return newScales;
            });
        }, 200);

        // toggle the subscribe button
        setUsers((prevUsers) =>
            prevUsers.map((user, i) =>
              i === index
                ? {
                    ...user,
                    subscribers: user.subscribers.includes(userId)
                      ? user.subscribers.filter((id) => id !== userId) // Unlike
                      : [...user.subscribers, userId], // Like
                  }
                : user
            )
        );


        try {

            await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                {},
                {
                    headers: {Authorization: accessToken}
                });

            // set the subscribe button to the opposite of what it was initially

        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        });
                    
                }
            }else{

                // toggle back if it fails
                setUsers((prevUsers) =>
                    prevUsers.map((user, i) =>
                      i === index
                        ? {
                            ...user,
                            subscribers: user.subscribers.includes(userId)
                              ? user.subscribers.filter((id) => id !== userId) // Unlike
                              : [...user.subscribers, userId], // Like
                          }
                        : user
                    )
                );
        
                console.log(error)
            }
        }
    }

    // check if the users array is empty or not
    if (!users || !Array.isArray(users)) {
        return <div>No users available</div>;
    }

    return (
        <Container>
            {users.map((user, index)=>(
                <Link key={index} className="transparent_click" to={`/@${user.userName}`}>
                    <UserItem>
                        <UserImageAndTextCon>
                            <UserImageCon>
                                {/* check if theres a profile picture */}
                                {!user.profilePicture
                                    ?<UserImage></UserImage>
                                    :<UserImage src={user.profilePicture} alt={`profile ${index}`}/>}
                            </UserImageCon>

                            <UserTextCon>
                                <UserName>
                                    @{shortenText(user.userName, 20)}

                                    {/* verification badge */}

                                    {/* get the exact verification badge type the user has if they have a verification badge */}
                                    {user.hasVerificationBadge&&
                                    <VerficationImageContainer>
                                        <VerficationImage src={user.verificationBadgeType==='regular'?RegularBadge : user.verificationBadgeType==='silver'?SilverBadge : user.verificationBadgeType==='organisation'? OrganisationBadge : user.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                        <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={7}/></Check>
                                    </VerficationImageContainer>}


                                </UserName>
                                <UserAbout>{shortenText(user.fullName, 30)}</UserAbout>
                            </UserTextCon>
                            
                        </UserImageAndTextCon>

                        {/* show subscribe button if id is not mine */}
                        {userId!==user._id&&<FollowButton onClick={(e)=>{

                            toggleSubscribeToUser({e: e, userIamSubscribingToId: user._id, index: index});

                        }} style={{transform: `scale(${buttonScales[index] || 1})`}}>
                            {user.subscribers.includes(userId)?'Unsubscribe':'Subscribe'}
                        </FollowButton>}
                    </UserItem>
                </Link>
            ))}
        </Container>
    )
}

export default Users