import styled from 'styled-components';
import Posts from '../components/Posts';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_ROUTE } from '../utils/Api';
import { refreshAccessToken } from '../utils/RefreshAccessToken';
import Users from '../components/Users';
// import HorizontalUsers from '../components/HorizontalUsers';



const Container = styled.div`
    flex: 1;  // use flex: 1 for all the pages container.
    // background-color: var(--twikVibe-dark-grey-bg);

    // @media screen and (max-width: 1100px){
    //     padding: 0;
    // }
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    

    @media screen and (max-width: 1100px){
        display: block;
    }
`;

const Title = styled.h1`
    font-size: 15px;
`;
const LeftContainer = styled.div`
    width: 500px;
    margin: auto;
    // height: 1000px;

    @media screen and (max-width: 1100px){
        
    }

    @media screen and (max-width: 600px){
        width: 100%;
    }



`;

const PostsContainer = styled.div`
    // height: 100vh; 
    // overflow-y: auto;
    padding: 10px;
    

`;

// const Line = styled.div`
//     height: 0.1px;
//     background: #5c5c5c;
//     margin: 40px 10px;
// `;
// const MenuContainer = styled.div`
//     display: flex;
//     align-items: center;
//     position: sticky;
//     top: 0px;
//     background: var(--twikVibe-dark-theme-bg-color);
//     // backdrop-filter: blur(10px);
//     justify-content: center;

//     z-index: 11;

//     @media screen and (max-width: 600px){
//         top: 60px;
//     }
// `;
// const MenuItem = styled.div`
//     padding: 20px;
//     cursor: pointer;
// `;
const RightContainer = styled.div`
    // width: 250px;
    position: sticky;
    top: 0;
    height: 100vh; 
    overflow: hidden;

    @media screen and (max-width: 1100px){
        display: none;
    }
`;

const UsersContainer = styled.div`
    // height: 100vh; 
    // overflow-x: hidden;
    width: 350px;
    padding: 10px;
`;


const Homepage = ({setShowBackButton, setShowPopupMenuButton, setShowHeader, storedHomepageScrollPosition, setStoredHomepageScrollPosition, setHomepageStoredPopularUsers, storedHomepagePopularUsers, storedHomepagePosts, setStoredHomepagePosts}) => {    
    const accessToken = localStorage.getItem('accessToken')
    const [posts, setPosts] = useState([]);
    const [popularUsers, setPopularUsers] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [loadingPopularUsers, setLoadingPopularUsers] = useState(true);
    const [showPosts, setShowPosts] = useState(true)





    // reveal side bar 
    useEffect(()=>{
        setShowHeader(true);

        // hide mobile back button button
        setShowBackButton(false)
        setShowPopupMenuButton(true)
    },[setShowHeader, setShowBackButton, setShowPopupMenuButton])

    const getUserProfile = useCallback(async({userId})=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            const userName = response.data.returnWithoutPassword.userName;
            const fullName = response.data.returnWithoutPassword.fullName;
            const profilePicture = response.data.returnWithoutPassword.profilePicture;
            const subscribers = response.data.returnWithoutPassword.subscribers;
            const hasVerificationBadge = response.data.returnWithoutPassword.hasVerificationBadge;
            const verificationBadgeType = response.data.returnWithoutPassword.verificationBadgeType;
            return {fullName: fullName, profilePicture: profilePicture, userName: userName, subscribers: subscribers, hasVerificationBadge: hasVerificationBadge, verificationBadgeType: verificationBadgeType};
        } catch (error) {
            
        }
    },[accessToken])


    
    const getPosts = useCallback(async () => {
        console.log('fetching posts...')
        try {
            const response = await axios.get(`${API_ROUTE}getLatestPosts`, {
                headers: { Authorization: accessToken },
            });


    
            if (response.data.length) {
                const userData = await Promise.all(
                    response.data.map(async (post) => {
                        const userProfile = await getUserProfile({ userId: post.userId });
                        return {
                            ...post,
                            userName: userProfile.userName,
                            fullName: userProfile.fullName, // Attach the fetched full name to the chat
                            profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                            subscribers: userProfile.subscribers,
                            hasVerificationBadge: userProfile.hasVerificationBadge,
                            verificationBadgeType: userProfile.verificationBadgeType,
                        };
                    })
                );

    
                setPosts(userData);

                console.log(userData)
    
                // Optionally store posts in local storage or state
                setStoredHomepagePosts(userData);
            }
    
            setLoadingPosts(false);
        } catch (error) {
            if (error.status === 403) {
                const { accessToken } = await refreshAccessToken();
                if (accessToken) {
                    // Retry fetching posts with refreshed token
                    getPosts();
                }
            } else {
                console.error(error);
            }
        }
    }, [accessToken, getUserProfile, setStoredHomepagePosts]);
    
    

    const getPopularUsersWithVerificationBadge = useCallback(async()=>{

        console.log('fetching...')
        try {
            const response = await axios.get(`${API_ROUTE}getPopularUsersWithVerificationBadge`,{
                headers: {Authorization: accessToken}
            });

            setPopularUsers(response.data);

            // set the the stored user for the general app as well to avoid refreh
            setHomepageStoredPopularUsers(response.data)
            
            
            setLoadingPopularUsers(false);
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    getPopularUsersWithVerificationBadge()
                }
            }else{
                console.log(error)
            }
        }
    },[accessToken, setHomepageStoredPopularUsers])

    const scrollToPosition = useCallback(() => {
        setTimeout(() => {
            window.scrollTo({ 
                top: storedHomepageScrollPosition,
                behavior: "smooth"
            });

            // set the back button tp to false in other for the page to not misbehave
            setShowPosts(false)
        }, 100);
    },[storedHomepageScrollPosition]);

    useEffect(()=>{

        // check if there are any posts stored  in the general app state first and if the back button was pressed
        if(storedHomepagePosts&&showPosts){
            setPosts(storedHomepagePosts);
            setPopularUsers(storedHomepagePopularUsers);

            scrollToPosition();

            

            setLoadingPosts(false);
            setLoadingPopularUsers(false);
        }else{

            // call new posts if there are no already stored posts in the state

            if(!storedHomepagePosts){
                getPosts();
                getPopularUsersWithVerificationBadge();
            }
            
        }
        
    },[ getPosts, getPopularUsersWithVerificationBadge, scrollToPosition, showPosts, storedHomepagePopularUsers, storedHomepagePosts])


    // check the exact position i am at in the screen
    useEffect(() => {
        const handleScroll = () => {
            setStoredHomepageScrollPosition(window.scrollY)
        };
    
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setStoredHomepageScrollPosition]);
    return (
        <Container>

            <InnerContainer>
                <div></div>
                <div></div>
                <LeftContainer>




                    <PostsContainer>
                        {loadingPosts?'Loading...':(
                            // keep alive stops the posts component from refreshing
                                <Posts posts={posts} setPosts={setPosts}/>
                            )}
                    </PostsContainer>


                </LeftContainer>

                <RightContainer>
                    <UsersContainer>
                        <Title>Suggested for you</Title>
                    
                        {loadingPopularUsers?'loading':<Users users={popularUsers} setUsers={setPopularUsers}/>}
                    </UsersContainer>
                    
                </RightContainer>
            </InnerContainer>
        
        </Container>
    )
}

export default Homepage