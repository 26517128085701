// import {  useState } from "react";
import styled from "styled-components"
// import ToggleSwitch from "./ToggleSwitch";
// import axios from "axios";
// import { API_ROUTE } from "../utils/Api";
// import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { Link } from "react-router-dom";

const Container = styled.div`
    position: fixed;
    bottom: 50px;
    left: 50px;
    padding: 10px;
    z-index:12;
    background: var(--twikVibe-dark-theme-color);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    width: 200px
    
`;

const MenuItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    font-weight: 600;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;



const PopupMenu = ({setPopupMenuOpen}) => {
    // const selecetedTheme = localStorage.getItem('selectedTheme');
    // const [currentMode, setCurrentMode] = useState(selecetedTheme==='dark'?'Dark Mode':'Light Mode')
    // const accessToken = localStorage.getItem('accessToken')
    
    
    // const setDarkModeTheme = async ({darkModeTheme}) => {
    //     try {
    //         await axios.post(`${API_ROUTE}updateProfile`,
    //             { darkModeTheme: darkModeTheme.toString()},
    //             {headers: {
    //                 Authorization: accessToken
    //             }})

    //     } catch (error) {
    //         if(error.status === 403){
    //             // refresh access token
    //             const {accessToken} = await refreshAccessToken();

    //             if (accessToken) {
    //                 // Optionally, update the authorization header with the new token
    //                 setDarkModeTheme()
        
    //             }
    //         }

    //         console.log(error.message)
    //     }
    // }

    // const setDarkMode = ()=>{
    //     document.querySelector("body").setAttribute('data-theme', 'dark')
    //     localStorage.setItem('selectedTheme', 'dark')
    //     setDarkModeTheme({darkModeTheme: true})
    // }

    // const setLightMode = ()=>{
    //     document.querySelector("body").setAttribute('data-theme', 'light')
    //     localStorage.setItem('selectedTheme', 'light')
    //     setDarkModeTheme({darkModeTheme: false})
    // }


    

    // if(selecetedTheme==='dark' || selecetedTheme === null){
    //     setDarkMode();
    // }



    // const toggleTheme = (e)=>{
    //     if(selecetedTheme!=='dark'){
    //         setDarkMode();
    //         // setCurrentMode('Dark Mode');
    //     } else {
    //         setLightMode();
    //         // setCurrentMode('Light Mode');
    //     }
    // }



    
    return (
        <Container>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings'>
                <MenuItem>Monetisation</MenuItem>
            </Link>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings'>
                <MenuItem>Settings</MenuItem>
            </Link>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings/display'>
                <MenuItem>Display</MenuItem>
            </Link>

            {/* <MenuItem>
                <ToggleSwitch
                    isChecked={selecetedTheme!=='dark'?false:true}
                    onChange={toggleTheme}
                />
            </MenuItem> */}
            <MenuItem>Log Out</MenuItem>

            
        </Container>
    )
}

export default PopupMenu